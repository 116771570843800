.App {
  text-align: center;
  background-color: white;
  /* min-height: 1500px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ////////////////////////////////////////////////////////////////////// */

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.scoreList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.score {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px 10px 10px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(0, 0, 0) 0px 5px 7.5px -2.5px;
}

.score:hover {
  background-color: #f5f5f5;
}

.scoreLink {
  color: black;
  text-decoration: none !important;
}

.scoreLink:hover {
  color: black;
}

.descLink {
  color: black;
  background-color: none;
  text-decoration: none;
}

.headLink {
  display: flex;
  justify-content: center;
  color: black;
  text-decoration: none;
}

.headLink:hover {
  color: black;
}

.scoreTitleContainer:hover {
  background-color: #f5f5f5;
}

.formThird {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgb(0, 0, 0) 0px 10px 15px -5px;
  margin: 10px 0 10px 0;
  max-width: 90%;
  min-width: 300px;
}

.formSpacing {
  margin: 10px 0 10px 0;
}

.scoreTitleContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
  margin: 10px 0 10px 0;
  max-width: 90%;
  min-width: 300px;
}

.title {
  padding: 10px 15px 0 15px;
}

.topScores {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;
}

.first {
  margin: 5px;
}

.second {
  margin: 5px;
}

.third {
  margin: 5px;
}

.fourth {
  margin: 5px;
}

.fifth {
  margin: 5px;
}

Table {
  background-color: white;
}

.spinner {
  margin: auto !important;
}

.loginBox {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  max-width: fit-content;
  min-width: 300px;
  margin-top: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0) 0px 0px 5px -2px;
}

.navButton {
  margin-top: 10px;
}

.MemberHeader {
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: 25px;
}

.memberPageName {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0) 0px 10px 15px -5px;
  margin: 10px 0 10px 0;
  max-width: 90%;
  min-width: 300px;
  height: fit-content;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-wrap: wrap;
}

.memberPageColumn {
  display: flex;
  flex-direction: row;
}

.memberPageNameLeft {
  margin-right: 30px;
}

.memberPageNameRight {
  margin-left: 30px;
}

.NavLogOut {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.allForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 0 5px 0;
}

.allFormScore {
  padding-left: 15px;
}

.allFormScoreInput {
  width: 100px !important;
}

.lookupScore {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
  min-width: 300px;
  padding: 10px;
}

.shareDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}